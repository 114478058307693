import { Loader } from '@googlemaps/js-api-loader';

export const maps = new Loader({
  apiKey: 'AIzaSyB-DLuc4c4Y6AmqqjfaSE_yo6UeQZHOcEE',
  libraries: ['places'],
});

// export const autoComplete = maps
//   .load()
//   .then((google) => new google.maps.places.AutocompleteServices());
