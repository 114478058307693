








































import BirthdayPicker from '@/components/inputs/BirthdayPicker.vue';
import Component from 'vue-class-component';
import GenderInput from '@/components/inputs/GenderInput.vue';
import Vue from 'vue';
import { Patient, PatientData, Practitioner, PractitionerData } from '@/models';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'PatientDetailsForm',
  components: {
    GenderInput,
    BirthdayPicker,
  },
})
export default class extends Vue {
  @Prop({ type: [Patient, Practitioner] }) readonly patient!: Patient | Practitioner;

  data: PatientData | PractitionerData | null = null;

  valid = false;

  mounted(): void {
    this.data = { ...this.patient.tempData };
  }

  cancel(): void {
    this.$emit('cancel');
  }

  submit(): void {
    this.$emit('submit', { ...this.data });
  }

  @Watch('data', { deep: true })
  onDataChanged(): void {
    this.$emit('input', { ...this.data });
  }
}
