


















/*global google*/
import {} from 'google.maps';
import Component from 'vue-class-component';
import Vue from 'vue';
import { AddressData } from '@/models/Address';
import { Watch } from 'vue-property-decorator';
import { maps } from '@/plugins';

type Address = Omit<AddressData, 'uuid'>;

type AddressComponents = {
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  country: string;
  locality: string;
  postal_code: string;
  route: string;
  street_number: string;
};

@Component({
  name: 'AddressAutoComplete',
})
export default class extends Vue {
  entries: google.maps.places.AutocompletePrediction[] | null = [];
  predictor!: google.maps.places.AutocompleteService;
  geocoder!: google.maps.Geocoder;
  address = '';
  search = '';
  formattedAddress: Address = {
    address_1: '',
    postcode: '',
    suburb: '',
    country: '',
    state: '',
    lat: '',
    lng: '',
  };

  filter(item: google.maps.places.AutocompletePrediction[], queryText: string, itemText: string): boolean {
    const result =
      itemText.toLocaleLowerCase().replaceAll(',', '').indexOf(queryText.toLocaleLowerCase().replaceAll(',', '')) > -1;
    return result;
  }

  registerPredictions(results: google.maps.places.AutocompletePrediction[] | null): void {
    this.entries = results;
  }

  geocodeAddress(placeId: string): void {
    this.geocoder.geocode({ placeId }, this.registerAddress);
  }

  registerAddress(results: google.maps.GeocoderResult[] | null): void {
    if (!results) {
      return;
    }
    this.splitAddress(results[0]);
  }

  splitAddress(address: google.maps.GeocoderResult): void {
    const components = {} as AddressComponents;
    address.address_components.forEach((component) => {
      Object.assign(components, {
        [component.types[0]]:
          component.types[0] == 'administrative_area_level_1' ? component.short_name : component.long_name,
      });
      return {
        value: component.long_name,
        type: component.types[0],
      };
    });
    this.formattedAddress.address_1 = `${components.street_number} ${components.route}`;
    this.formattedAddress.postcode = components.postal_code;
    this.formattedAddress.suburb = components.locality;
    this.formattedAddress.state = components.administrative_area_level_1;
    this.formattedAddress.country = components.country;
    this.formattedAddress.lng = address.geometry.location.lng();
    this.formattedAddress.lat = address.geometry.location.lat();
    this.$emit('changed', this.formattedAddress);
  }

  mounted(): void {
    maps.load().then((google) => {
      this.predictor = new google.maps.places.AutocompleteService();
      this.geocoder = new google.maps.Geocoder();
    });
  }

  @Watch('search')
  async onSearchChanged(val: string): Promise<void> {
    if (this.search && val) {
      await this.predictor.getPlacePredictions({ input: val }, this.registerPredictions);
    }
  }

  @Watch('address')
  onAddressChanged(val: google.maps.places.PlaceResult): void {
    // TODO - handle no place id
    val.place_id ? this.geocodeAddress(val.place_id) : '';
  }
}
