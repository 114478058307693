











import AddressForm from '@/components/forms/AddressForm.vue';
import Component from 'vue-class-component';
import Vue from 'vue';
import { Address, AddressData } from '@/models/Address';
import { Patient, Practitioner } from '@/models';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'PatientAddressForm',
  components: {
    AddressForm,
  },
})
export default class extends Vue {
  @Prop({ type: [Patient, Practitioner] }) readonly patient!: Patient | Practitioner;

  valid = false;

  get address(): Address | null {
    return this.patient.address;
  }

  get states(): string[] {
    return Address.states;
  }

  cancel(): void {
    this.$emit('cancel');
  }

  updateAddressData(value: AddressData): void {
    if (this.address) {
      this.address.tempData = value;
    }
  }

  async submit(): Promise<void> {
    this.patient.address = this.address;

    if (!this.address) {
      return;
    }

    if (this.address.exists()) {
      await this.address.update();
    } else {
      await this.address.create();
    }

    this.patient.refresh();
    this.$emit('submit');
    this.$emit('input', this.patient.tempData);
  }
}
