

















































import AddressAutoComplete from '@/components/inputs/AddressAutoComplete.vue';
import Component from 'vue-class-component';
import Vue from 'vue';
import { Address, AddressData } from '@/models/Address';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'AddressForm',
  components: {
    AddressAutoComplete,
  },
})
export default class extends Vue {
  @Prop(Address) readonly address!: Address;

  data: AddressData | null = null;

  valid = false;
  editing = false;

  get states(): string[] {
    return Address.states;
  }

  mounted(): void {
    this.data = { ...this.address.tempData };
  }

  cancel(): void {
    this.$emit('canceled');
  }

  updateAddress(value: Address): void {
    Object.assign(this.data, value);
  }

  submit(): void {
    this.$emit('submit', { ...this.data });
  }

  @Watch('data', { deep: true })
  onDataChanged(): void {
    this.$emit('input', { ...this.data });
  }
}
